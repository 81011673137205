.abyss-tooltip-content {
  font-family: var(--abyss-fonts-primary);
}

.full-screen-modal.abyss-modal-content-container {
  position: relative !important;
  top: var(--abyss-space-sm);
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  padding: 0 !important;
}

.full-screen-modal.abyss-modal-container div[class*='isDrawer-false'] {
  padding-right: var(--abyss-space-md) !important;
}

.full-screen-modal.abyss-modal-content-container .abyss-modal-body,
.full-screen-modal.abyss-modal-content-container .abyss-modal-section-root {
  position: relative !important;
  width: inherit !important;
  height: inherit !important;
  max-height: inherit !important;
}

.full-screen-modal.abyss-modal-content-container h6 {
  color: var(--abyss-colors-black) !important;
  margin-bottom: var(--abyss-space-xs) !important;
}

.full-screen-modal.abyss-modal-content-container .abyss-divider-root {
  background-color: var(--abyss-colors-gray3) !important;
  margin-top: var(--abyss-space-md) !important;
  margin-bottom: var(--abyss-space-lg) !important;
}

.full-screen-modal.abyss-modal-content-container .chartStyles {
  position: relative !important;
  width: inherit !important;
  height: revert !important;
  max-height: calc(100vh - 200px) !important;
}

.full-screen-modal.abyss-modal-content-container .chartStyles .abyss-grid-col {
  overflow: scroll !important;
}

.full-screen-modal.abyss-modal-close-button {
  display: none;
  visibility: hidden;
}

.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
}

.static-table .abyss-table-row {
  background-color: transparent !important;
}

.abyss-dropdown-menu-item.no-padding {
  padding-left: 5px !important;
}

.abyss-dropdown-menu-item.no-margin {
  margin-left: 0px !important;
}

*[class*='isDisabled-true'] .abyss-icon {
  color: var(--abyss-colors-gray5) !important;
}

.eid-remove-icon {
  color: var(--abyss-colors-error1) !important;
}

#eid-association-partial-error {
  max-height: none !important;
}

*[class*='isInvalid-true'] {
  position: absolute;
  bottom: 4px;
  padding: 4px;
  z-index: 999;
  background-color: var(--abyss-colors-error2);
  border: 1px solid var(--abyss-colors-error1);
}

*[class*='isDisabled-true'],
*[class*='variant-isDisabled'] {
  color: var(--abyss-colors-gray5) !important;
}

*[class*='isDisabled-true'] .abyss-icon,
*[class*='variant-isDisabled'] .abyss-icon {
  fill: var(--abyss-colors-gray5) !important;
  color: var(--abyss-colors-gray5) !important;
}

.abyss-nav-menu-menu button[data-state='open'] .abyss-icon,
.abyss-nav-menu-menu button:hover .abyss-icon {
  color: var(--abyss-colors-foreground) !important;
  fill: var(--abyss-colors-foreground) !important;
}
